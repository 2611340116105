* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0;
  padding: 0;
  color: #212124;
} 
#root {
  overflow: hidden;
}
body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
button {
  outline: none;
  background: none;
  margin: 0;
  border: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}
img {
  pointer-events: none;
}
